<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill following details</h4>
      <form autocomplete="off">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.title') && videoDetail.title!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.title') ? true : false)"
                v-validate="'required|max:150'"
                name="title"
                data-vv-as="title"
                label="Title"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.title')"
                v-model="videoDetail.title"
              />
            </div>
          </vs-col>
          <vs-col v-if="videoDetail.video != ''">
            <div class="w-full m-5">
                <iframe :src="videoDetail.video"></iframe>
            </div>
            <div class="black_overlay" @click.prevent="removeVideo()" style="display: inline-block; position: relative; bottom: 200px; left: 320px;">
              <div style=" z-index: 0; top: 10%;">
                <a class="close" @click.prevent="removeVideo()"><h2>&times;</h2></a>
              </div>
            </div>
          </vs-col>
          <vs-col  vs-type="flex" vs-justify="left" vs-align="left" vs-w="12" v-if="videoDetail.video == ''">
            <div class="w-full m-5">
              <label>No video uploaded</label>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div
              id="safetyVideo"
              style="text-align:center;position:relative;overflow: visible;"
              class="vs-con-loading__container m-5"
            >
              <label
                class="image-upload"
                style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
              >
                Upload a safety video
                <input
                  type="file"
                  @change="uploadVideo"
                  id="file-input"
                  hidden
                />
              </label>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.heading') && videoDetail.heading!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.heading') ? true : false)"
                v-validate="'required'"
                name="heading"
                data-vv-as="heading"
                label="Heading"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.heading')"
                v-model="videoDetail.heading"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full m-5">
              <label class="ml-1">Bullets</label>
              <div class="buttonInside" v-for="(bullet, index) in videoDetail.bullets" :key="index">
                <vs-input
                @keyup.enter="add()"
                class="w-full mt-2"
                ref="'p' + index"
                v-model="videoDetail.bullets[index]" />
                <span class="button" title="Remove" @click.prevent="remove(index)">X</span>
              </div>
              <span v-if="bulletError" style="color:red;">Empty bullet is not allowed.</span>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button
                size="normal"
                @click="saveData"
                icon-pack="feather"
                class="mr-2"
              >Save</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "safety-video",
  data: () => ({
    bulletError:false,
    videoDetail: {
      id: "",
      title: "",
      video: "",
      heading: "",
      bullets: [],
    },
  }),
  methods: {
    ...mapActions("admin", ["getSafetyVideoDetail", "updateSafetyVideo", "uploadSafetyVideo", "deleteSafetyVideo"]),
    async saveData() {
      let self = this;
      self.bulletError = false;
      let user = self.$store.state.AppActiveUser.userRole;
      let isValid = await this.$validator.validateAll("basic");
      await this.$validator.validate()
      if (isValid) {
        await this.updateSafetyVideo(this.videoDetail)
          .then(res => {
            self.$vs.notify({
              title: "Success",
              text: "Safety video updated Successfully.",
              color: "success"
            });
            this.$router.push("/super-admin/safety-video");
          })
          .catch(err => {
            self.$vs.notify({
              title: "Failed",
              text: "Safety video update failed",
              color: "danger"
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function(value, key) {
        if(value.path[0]=='bullets'){
          self.bulletError = true;
        }
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, "")
        });
      });
    },
    async getDetail() {
        const self = this;
        await this.getSafetyVideoDetail()
          .then(res => {
              const data = res.data.data;
              self.videoDetail.id = data._id;
              self.videoDetail.title = data.title;
              self.videoDetail.heading = data.description.heading;
              self.videoDetail.bullets = data.description.bullets;
              self.videoDetail.video = data.url;
          })
          .catch(err => {});
    },
    async uploadVideo(event) {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#safetyVideo",
        scale: 0.4
      });
      const self = this;
      const video = event.target.files[0];
      const getExtension = video.name.split('.')[video.name.split('.').length-1];

      const validExtension = [
        'mp4', 'flv', 'mkv', '3gp'
      ];
      if(!validExtension.includes(getExtension)){
        const invalidVideo = 'Selected video type is invalid.';
        return false;
      }

      await this.uploadSafetyVideo(video)
          .then(res => {
            self.videoDetail.video = res.data.data.Location
            self.$vs.notify({
              title: "Update",
              text: "Safety video uploaded Successfully.",
              color: "success"
            });
            self.$vs.loading.close("#safetyVideo > .con-vs-loading");
          })
          .catch(err => {
            self.$vs.notify({
              title: "Failed",
              text: "Safety video upload failed.",
              color: "danger"
            });
            self.$vs.loading.close("#safetyVideo > .con-vs-loading");
          });
    },
    add() {
      this.videoDetail.bullets.push('');
      // this.$nextTick(()=>{
      //   // console.log(this.$refs['p'+(j-1)][0]);
      //   console.log(this.$refs);
      //   this.$refs.p2.focus();
      // });
    },
    remove(key) {
      if(this.videoDetail.bullets.length>1){
        this.videoDetail.bullets.splice(key,1)
      }
    },
    async removeVideo(){
      let self = this;
        await this.deleteSafetyVideo(this.videoDetail.id)
          .then(res => {
            self.videoDetail.video = '';
            self.$vs.loading.close("#safetyVideo > .con-vs-loading");
          })
          .catch(err => {
            self.$vs.notify({
              title: "Failed",
              text: "Safety video removal failed.",
              color: "danger"
            });
            self.$vs.loading.close("#safetyVideo > .con-vs-loading");
          });
    }
  },
  watch: {
    'videoDetail.bullets': function () {
      if(this.videoDetail.bullets.length>0) {
          this.bulletError = false;
      }
    }
  },
  async created() {
      await this.getDetail();
  }
};
</script>
<style>
  .buttonInside{
    position:relative;
    margin-bottom:10px;
  }
  .buttonInside > .button{
    position:absolute;
    right: 0px;
    top: 0px;
    border:none;
    height:38px;
    width:20px;
    outline:none;
    border-radius: 0% 30% 30% 0%;
    text-align:center;
    font-weight:bold;
    padding:9px 4px;
    background:#ff5c61;
  }
  .buttonInside > .button:hover{
   cursor:pointer;
  }

</style>
